var createjs = require('createjs');

var BaseObject = function() {
    this.callback = undefined;
    this.stageObj = undefined;
    this.container = undefined;
    this.src = undefined;
};

BaseObject.prototype.cleanConfig = function (config) {
    if (typeof config.scale !== "undefined") {
        config.scaleX = config.scale;
        config.scaleY = config.scale;
    }
    return config;
};

BaseObject.prototype.set = function (config) {
    config = this.cleanConfig(config);
    this.stageObj.set(config);
    return this;
};

BaseObject.prototype.hide = function() {
    this.stageObj.set({visible: false});
    return this;
};

BaseObject.prototype.show = function() {
    this.stageObj.set({visible: true});
    return this;
};

BaseObject.prototype.isVisible = function() {
    return this.stageObj.visible;
};

BaseObject.prototype.moveTo = function (x, y) {
    var config = (isNaN(x)) ? x : {x:x, y:y};
    this.stageObj.set(config);
    return this;
};

BaseObject.prototype.alpha = function (alpha) {
    this.stageObj.set({alpha: alpha});
    return this;
};

BaseObject.prototype.getSrc = function () {
    return this.src;
};

BaseObject.prototype.getContainer = function () {
    return this.container;
};

BaseObject.prototype.getStageObj = function () {
    return this.stageObj;
};

BaseObject.prototype.scale = function (scale) {
    this.stageObj.set({scaleX: scale, scaleY: scale});
    return this;
};

BaseObject.prototype.on = function(event,  callback ) {
    this.callback = callback;
    if (this.stageObj) this.stageObj.on(event, callback);
    return this;
};

BaseObject.prototype.extend = function (attr, attr2) {
    var x;
    if (typeof attr2 !== "undefined") {
        for (x in attr2) {
            attr[x] = attr2[x];
        }
        return attr;
    }
    else {
        for (x in attr) {
            this[x] = attr[x];
        }
        return this;
    }
};

BaseObject.prototype.delete = function (stage) {
    stage = stage || this.stageObj.parent;
    if (this.stageObj) {
        if (this.container) {
            stage.removeChild(this.container);
            delete this.container;
        }
        else {
            stage.removeChild(this.stageObj);
            delete this.stageObj;
        }
    }

    return this;
};

BaseObject.prototype.bringToFront = function (stage, object) {
    object = object || this.container || this.src;
    stage = stage || this.stageObj.parent;
    stage.setChildIndex(object, stage.getNumChildren() - 1);
    return this;
};

BaseObject.prototype.bringToBack = function (stage, object) {
    object = object || this.container || this.src;
    stage = stage || this.stageObj.parent;
    stage.setChildIndex(object, 0);
    return this;
};

BaseObject.prototype.get = function (attr) {
    if (this.src) return this.src[attr];
    if (this.stageObj) return this.stageObj[attr];
    return undefined;
};

BaseObject.prototype.addPayload = function (attr) {
    if (this.src) {
        this.src.payload = this.src.payload || {};
        this.src.payload = this.extend(this.src.payload, attr);
    }
    return this;
};

BaseObject.prototype.getPayload = function (attr) {
    return (this.src && this.src.payload && this.src.payload[attr]) ? this.src.payload[attr] : undefined;
};

BaseObject.prototype.cache = function (options) {
    options = options || {};
    if (this.stageObj) {
        var bounds = this.stageObj.getBounds();
        this.extend(bounds, options);
        this.stageObj.cache(bounds.x, bounds.y, bounds.width, bounds.height);
    }
    return this;
};

BaseObject.prototype.uncache = function (attr) {
    if (this.stageObj) this.stageObj.uncache();
    return this;
};

module.exports = BaseObject;
