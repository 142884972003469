var c = require('createjs');
var registry = require('./registry');
var Sprite = require('./sprite');
var loader = require('./loader');
var _center = false;
var _autoscale = false;
var _scale;
var _bounds;
var _config;

var TextObject = function (id, props) {
    _config = registry.get("config");
    // check for props to exist, for property to exist in props or set the default value
    _center = props && props.center || false;
    _autoscale = props && props.autoscale || false;
    _scale = props && props.scale || false;
    _bounds = props && props.bounds || _bounds;
    this.id = id;
};
TextObject.prototype = new Sprite();

TextObject.prototype.getFont = function (text, data) {
    if (this.src) return this.src;
    else {
        var conf = _config.spritesheets[this.id];
        conf = this.extend(conf, data);
        conf.images = [loader.getItem(this.id)];
        var spriteSheet = new c.SpriteSheet(conf);
        var bitmapText = new c.BitmapText(text, spriteSheet);
        if (conf.config) bitmapText.set(this.cleanConfig(conf.config));
        return bitmapText;
    }
};

TextObject.prototype.write = function (stage, text, inContainer, data) {
    text = (text === "undefined") ? "" : text;
    if (!this.src) this.src = this.getFont(text + '', data);
    this.stageObj = stage.addChild(this.src);
    if (inContainer) this.container = stage;
    _center && this.center();
    _autoscale && this.autoscale(text);
    return this;
};

TextObject.prototype.text = function (text) {
    if (this.src) this.src.text = text + '';
    _center && this.center();
    _autoscale && this.autoscale(text);
    return this;
};

TextObject.prototype.center = function (props) {
    var bounds = props && props.bounds || _bounds || this.stageObj.getBounds();
    var xOffset = props && props.offset && props.offset.x || 0;
    var yOffset = props && props.offset && props.offset.y || 0;

    if (!bounds) return this;

    var x = (props && props.x) || bounds.x;
    var y = (props && props.y) || bounds.y;

    if (x == 0 || y == 0) {
        x = (props && props.x) || this.stageObj.parent && this.stageObj.parent.x || 460;
        y = (props && props.y) || this.stageObj.parent && this.stageObj.parent.y || 300;
    }

    this.set({
        x: x + xOffset,
        y: y + yOffset,
        regX: parseInt(bounds.width / 2),
        regY: parseInt(bounds.height / 2)
    });

    return this;
};

TextObject.prototype.autoscale = function (text) {
    if (typeof text === "string"
        || typeof text === "number") {
        var len = (text + '').length;
        // len < 4, set the scale to 1
        if (len > 9) {
            this.scale(_scale[9]);
        }
        else {
            this.scale(_scale[len]);
        }
        return this;
    }
};

module.exports = TextObject;
