var baseObject = require('./baseObject');
var loader = require('./loader');
var registry = require('./registry');
var config;

var DrawObject = function (id) {
    config = registry.get("config");
    this.id = id;
};

DrawObject.prototype = new baseObject();

DrawObject.prototype.getItem = function () {
    return (this.id) ? loader.getItem(this.id) : false;
};

DrawObject.prototype.getSprite = function () {
    if (this.src) return this.src;
    else {
        var img = (this.id) ? loader.getSprite(this.id) : false;
        if (img) {
            if (config.sprites.data[this.id]) img.set(this.cleanConfig(config.sprites.data[this.id]));
        }
        return img;
    }
};

DrawObject.prototype.draw = function (stage, raw, inContainer) {
    if (!this.src) this.src = this.getSprite();
    if (raw) {
        return this.src;
    }
    else {
        if (this.src) {
            this.stageObj = stage.addChild(this.src);
            //this.stageObj.snapToPixelEnabled = true;
            //this.stageObj.snapToPixel = true;
            //var bounds = this.stageObj.getBounds();
            //this.stageObj.cache(bounds.x,bounds.y,bounds.width,bounds.height);
        }
        if (inContainer) this.container = stage;
        return this;
    }
};

DrawObject.prototype.align = function (align, offset, absolute) {
    align = align || "center";
    offset = offset || 0;
    absolute = absolute || true;
    var bounds = this.src.getBounds();

    if (!bounds) return this;
    if (!this.stageObj) return this;

    var parentBounds = (!absolute && this.stageObj && this.stageObj.parent && this.stageObj.parent.getBounds())
        || {width: config.canvas.width, height: config.canvas.height};
    var x = this.stageObj.x;
    var y = this.stageObj.y;
    var regX = parseInt(bounds.width / 2);
    var regY = parseInt(bounds.height / 2);

    if (align === "center") {
        x = parseInt(parentBounds.width / 2) + offset;
    }
    if (align === "left") {
        x = 0 + regX + offset;
    }
    if (align === "right") {
        x = parentBounds.width - regX - offset;
    }
    if (align === "middle") {
        y = parseInt(parentBounds.height / 2) + offset;
    }
    if (align === "top") {
        y = 0 + regY + offset;
    }
    if (align === "bottom") {
        y = parentBounds.height - regY - offset;
    }

    this.set({
        x: x,
        y: y,
        regX: regX,
        regY: regY
    });

    return this;
};

DrawObject.prototype.center = function (offset, absolute) {
    return this.align("center", offset, absolute);
};

DrawObject.prototype.left = function (offset, absolute) {
    return this.align("left", offset, absolute);
};

DrawObject.prototype.right = function (offset, absolute) {
    return this.align("right", offset, absolute);
};

DrawObject.prototype.middle = function (offset, absolute) {
    return this.align("middle", offset, absolute);
};

DrawObject.prototype.top = function (offset, absolute) {
    return this.align("top", offset, absolute);
};

DrawObject.prototype.bottom = function (offset, absolute) {
    return this.align("bottom", offset, absolute);
};

module.exports = DrawObject;
