var Util = function() {
    this.deepExtend = function (destination, source) {
        for (var property in source) {
            if (source[property] && source[property].constructor &&
                source[property].constructor === Object) {
                destination[property] = destination[property] || {};
                this.deepExtend(destination[property], source[property]);
            } else {
                destination[property] = source[property];
            }
        }
        return destination;
    };
    this.extend = function (attr, attr2) {
        attr2 = attr2 || {};
        var x, result;
        if (attr) result = this.clone(attr);
        else  return undefined;
        for (x in attr2) {
            result[x] = attr2[x];
        }
        return result;
    };
    this.clone = function clone(obj) {
        if (obj == null || typeof(obj) != 'object')
            return obj;
        var newObj = obj.constructor();
        for (var x in obj)
            newObj[x] = clone(obj[x]);
        return newObj;
    };

    this.delay = function(callbacks) {

        var self = this,
            i = 0;

        function next() {
            callbacks[i++].callback.call(self);
            if (i < callbacks.length) {
                setTimeout(next, callbacks[i].delay);
            }
        }
        setTimeout(next, callbacks[i].delay);
    };
};

module.exports =  new Util();