var util = require('./util');
var Registry = function(){
    this.data = {};

    this.add = function(index, data){
        if (!this.data[index]) { this.data[index] = {};}
        this.data[index] = util.extend(this.data[index], data);
    }

    this.get = function(index){
        return this.data[index];
    }
}

module.exports = new Registry();