var dispatcher = require('./dispatcher');

module.exports = function() {
    var _version = "0.1";

    var RealTimeObj = function(conf) {
        var self = this;
        this.connection = $.connection;
        this.hub = $.connection[conf.hubName];
        this.connection.hub.url = conf.url;
        this.connection.hub.qs = conf.qs;
        this.version = _version;
        this.subscriptions = {};
        this.ready = false;

        this.hub.client.notify = function(data) {
            self.subscriptions[data.Notification] && console.log(self.subscriptions[data.Notification], JSON.parse(data.PayLoad));

            self.subscriptions[data.Notification] && dispatcher.dispatch(
                self.subscriptions[data.Notification],
                JSON.parse(data.PayLoad)
            );
        };

        this.connection.hub.start().done(function() {
            self.ready = true;
        });

        for (var key in conf.subscriptions) {
            if (conf.subscriptions.hasOwnProperty(key)) {
                self.subscriptions[key] = conf.subscriptions[key];
            }
        }
    };

    // public properties and methods
    var p = RealTimeObj.prototype;

    p.selfcast = function(conf) {
        this.hub.server.selfcast(conf.type, conf.msg);
    };

    return RealTimeObj;
};