var EventEmitter = function() {
    this._pre = 'on_';
    this.listeners = {};

};

EventEmitter.prototype.register = function(eventName, callback, objectBinded) {
    var _eventName = this._pre + eventName;
    if(typeof this.listeners[_eventName] == 'undefined') {
        this.listeners[_eventName] = [];
    }
    this.listeners[_eventName].push([ !objectBinded ? this : objectBinded, callback]);
};

EventEmitter.prototype.emit = function(eventName, params) {
    var _eventName = this._pre + eventName;
    if(typeof this.listeners[_eventName] != 'undefined') {
        for(var i = 0, l = this.listeners[_eventName].length; i < l; i++) {
           this.listeners[_eventName][i][1].call(this.listeners[_eventName][i][0], eventName, params);
        }
    }
};

EventEmitter.prototype.getListeners = function(eventName){
    var _eventName = this._pre + eventName;
    return !eventName ? this.listeners : this.listeners[_eventName];
};

module.exports = new EventEmitter();