// Alternative to node module events with myself class
var EventEmitter = require("./eventEmitter.js");

// Wrapper about node module events, only implement two methods emit and on
// http://nodejs.org/api/events.html#events_events
// var EventEmitter = require("events").EventEmitter;

var Dispatcher = function(){
    this.eventEmitter = EventEmitter;
};

Dispatcher.prototype.dispatch = function(eventName, params){
    return this.eventEmitter.emit(eventName, params);
};

Dispatcher.prototype.addEventListener = function(eventName, callback){
    return this.eventEmitter.register(eventName, callback);
};

Dispatcher.prototype.listeners = function(eventName){
    return this.eventEmitter.getListeners();
};

module.exports = new Dispatcher();