var dispatcher = require('./dispatcher');
var c = require('createjs');
var preload = require('preload');
var sound = require('./sound');
var queue;
var spriteAliases;

var Loader = function () {
    var _init = function (config) {
        var i, j;

        // remember aliases
        spriteAliases = config.sprites["alias"] || [];

        // set up the load queue
        queue = new c.LoadQueue();
        queue.installPlugin(c.Sound);
        queue.on("progress", _handleProgress, this);
        queue.on("fileload", _handleFileLoad, this);
        queue.on("complete", _handleLoadComplete, this, true);

        // let it handle multiple loads concurrently:
        queue.setMaxConnections(8);

        // add version to bust cache
        for (i = 0, j = config.sounds.manifest.length; i < j; i++) {
            config.sounds.manifest[i].src += '?v=' + config.version;
        }
        for (i = 0, j = config.sprites.manifest.length; i < j; i++) {
            config.sprites.manifest[i].src += '?v=' + config.version;
        }
        // load assets
        queue.loadManifest([].concat(config.sounds.manifest, config.sprites.manifest), true);
    };

    var _handleProgress = function (event) {
        dispatcher.dispatch('update-progress', {progress: event.loaded / event.total});
    };

    var _handleFileLoad = function (event) {
        var e = event.item;
    };

    var _handleLoadComplete = function () {
        // hide progress bar
        dispatcher.dispatch('update-progress', {progress: 101});
        // notify the game that we are ready to start
        dispatcher.dispatch('ready');
        // start music
        sound && sound.playMusic && sound.playMusic();
    };

    var _getItem = function(id) {
        id = _getAlias(id);
        return queue.getResult(id);
    };

    var _getSprite = function(id, spritesheet) {
        id = _getAlias(id);
        if (spritesheet) {          // webgl
            return new c.Sprite(spritesheet, _getItem(id));
        }
        else {                      // canvas 2d
            return new c.Bitmap(_getItem(id));
        }
    };

    var _getAlias = function(id) {
        return (spriteAliases[id]) ? spriteAliases[id] : id;

    };

    // public properties and methods
    return {
        init: _init,
        getItem: _getItem,
        getSprite: _getSprite
    };
};

module.exports = new Loader();
