var c = require('createjs');
var store = require('store');//.namespace('slot');

var soundObj = function () {
    // private properties and methods
    var delayNode, music, mConfig,
        mute = store && store.has('mute') ? store('mute') : false;

    var _init = function (config) {
        mConfig = config;
        // initiate audio plugin
        c.Sound.registerPlugins([c.WebAudioPlugin, c.HTMLAudioPlugin]);
        c.Sound.alternateExtensions = ["mp3", "ogg"];
        c.Sound.defaultInterruptBehaviour = c.Sound.INTERRUPT_LATE;
};

    var _playMusic = function (id) {
        if (!music) {
            music = c.Sound.play("music", {loop: -1});
            _setMusicVolume(mConfig.volumeSubtle, 3000);
            _setupMusicFX(music);
        } else {
            if (!mute) music.resume();
        }
        if (mute) music.pause();
        return this;
    };

    var _setupMusicFX = function (soundInstance) {
        if (!soundInstance.gainNode) {
            return;
        }
        delayNode = c.WebAudioPlugin.context.createDelay();
        delayNode.connect(c.WebAudioPlugin.dynamicsCompressorNode);
        delayNode.delayTime.value = 0;
        soundInstance.gainNode.connect(delayNode);
    };

    var _setMusicVolume = function (volume, time) {
        c.Tween.get(music, {override: true}).to({volume: volume}, time);
    };

    var _setMusicStrong = function () {
        _setMusicVolume(mConfig.volumeStrong, 2000);
    };

    var _setMusicSubtle = function () {
        _setMusicVolume(mConfig.volumeSubtle, 2000);
    };

    var _playSoundFX = function () {
        if (!delayNode) {
            return;
        }
        c.Tween.get(delayNode.delayTime, {override: true})
            .to({value: 1}).to({value: 0}, 2200, c.Ease.bounceInOut);
    };

    var _playSound = function (id, loop, defaultSnd) {
        if (mute) {
            return;
        }
        var instance = createjs.Sound.play(id, createjs.Sound.INTERRUPT_EARLY, 0, 0, loop);
        if (instance.playState === createjs.Sound.PLAY_FAILED && defaultSnd) {
            createjs.Sound.play(defaultSnd, createjs.Sound.INTERRUPT_EARLY, 0, 0, loop);
        }
    };

    var _mute = function () {
        mute = true;
        store('mute', true);
        music.pause();
    };

    var _unMute = function () {
        mute = false;
        store('mute', false);
        music.resume();
    };

    var _toggle = function(){
        mute = !mute;
        store('mute', mute);
        music[(mute) ? "pause" : "resume"]();
        return mute
    }

    // public properties and methods
    return {
        state: mute,
        toggle : _toggle,
        init: _init,
        play: _playSound,
        playMusic: _playMusic,
        setMusicVolume: _setMusicVolume,
        mute: _mute,
        unMute: _unMute
    };
};

module.exports = new soundObj();