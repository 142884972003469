var registry = require("./registry");
var util = require("./util");
var reqwest = require("reqwest");

var request = function () {
    var config = {
        timeout: 1000 *6,
        type: "json",
        method: "GET",
        contentType: "application/json",
        crossOrigin: true,
        headers: ''
    };

    var self = this;

    var call = function(url, success, error, data, options) {
        var conf = util.extend(self.config, options || {});
        var userInfo = registry.get("user");
        var configGame = registry.get("config");
        if (userInfo && userInfo.TOKEN) {
            conf.headers = { "Authorization": "Bearer " + userInfo.TOKEN};
        }
        conf.url = url || configGame.api;
        conf.data = data || {};
        conf.error = function (err) {
            console.debug("[error] error trying to connect to: " + configGame.api );
            console.log(err, this);
            if (error) error(err);
        };
        conf.success =function(response){
            if (success) success(response);
        }
        reqwest(conf)
    };

    this.config = config;

    this.get = function (url, success, error, data) {
        call(url, success, error, data);
    };

    this.post = function (url, success, error, data) {
        call(url, success, error, data, {method: 'POST'});
    };

    this.setConfig = function (conf) {
        this.config = util.deepExtend(this.config, conf);
    };

    this.restoreConfig = function(){
        this.config = config;
        return this.config;
    }
};


module.exports = new request();