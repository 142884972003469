var c = require('createjs');

module.exports = function () {

    // private properties and methods
    var _create = function(id, webgl) {
        var stage;
        if (webgl) {
            stage = new c.SpriteStage(id);
        }
        else {
            stage = new c.Stage(id);
        }
        stage.snapToPixelEnabled = true;
        return stage;
    };

    // public properties and methods
    return {
        create: _create
    };
};