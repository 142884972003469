var queue = function () {

    // private properties and methods
    var _queues = [];
    var _paused = [];

    var _add = function(callback, time, id) {
        id = id || "default";
        if (!_queues[id]) _queues[id] = [];
        _queues[id].push({callback: callback, time: time});
    };

    var _execute = function(id) {
        id = id || "default";
        if (_queues[id]
            && _queues[id].length > 0
            && !_paused[id]) {
            var action = _queues[id].shift();
            action.time && setTimeout(function() {
                action.callback && action.callback();
                _execute(id);
            }, action.time);
        }
    };

    var _clear = function(id) {
        id = id || "default";
        _queues[id] = [];
    };

    var _pause = function(id) {
        id = id || "default";
        _paused[id] = true;
    };

    var _resume = function(id) {
        id = id || "default";
        _paused[id] = false;
        _execute(id);
    };

    // public properties and methods
    return {
        add: _add,
        execute: _execute,
        play: _execute,
        clear: _clear,
        pause: _pause,
        resume: _resume
    };
};

module.exports = queue;