var loader = require('./loader');
var c = require("createjs");
var Sprite = require('./sprite');
var registry = require('./registry');
var config;


var AnimationObject = function (id) {
    config = registry.get("config");
    this.id = id;
};
AnimationObject.prototype = new Sprite();

AnimationObject.prototype.getAnimation = function (start, data) {
    if (this.src) return this.src;
    else {
        var conf = config.spritesheets[this.id];
        conf = this.extend(conf, data);
        conf.images = [loader.getItem(this.id)];
        var spriteSheet = new c.SpriteSheet(conf);
        var animation = new c.Sprite(spriteSheet, start);
        if (conf.config) animation.set(this.cleanConfig(conf.config));
        return animation;
    }
};

AnimationObject.prototype.animate = function (stage, start, inContainer, data) {
    start = (typeof start === "undefined") ? "idle" : start;
    if (!this.src) this.src = this.getAnimation(start, data);
    this.stageObj = stage.addChild(this.src);
    //this.stageObj.snapToPixelEnabled = true;
    //this.stageObj.snapToPixel = true;
    //var bounds = this.stageObj.getBounds();
    //this.stageObj.cache(bounds.x,bounds.y,bounds.width,bounds.height);
    if (inContainer) this.container = stage;
    return this;
};

AnimationObject.prototype.play = function (animation, callback) {
    var self = this;
    //this.stageObj.uncache();
    this.stageObj.gotoAndPlay(animation);
    this.src.addEventListener("animationend", function (event) {
        self.src.removeAllEventListeners("animationend");
        //var bounds = self.stageObj.getBounds();
        //self.stageObj.cache(bounds.x,bounds.y,bounds.width,bounds.height);
        if (callback) callback();
    });
    return this;
};

AnimationObject.prototype.stop = function (animation) {
    this.stageObj.gotoAndStop(animation);
    return this;
};

module.exports = AnimationObject;