var preload = require('preload');
var sound = require('sound');
var tween = require('tween');
var Promise = require('bluebird');
var reqwestSingleton = require('./../modules/request');
var SpriteClass = require('./../modules/sprite');
var TextClass = require('./../modules/text');
var SpriteSheetClass = require('./../modules/spritesheet');
var ContainerClass = require('./../modules/container');
var QueueSingleton = require('./../modules/queue');
var SoundSingleton = require('./../modules/sound');
var RealtimeClass = require('./../modules/realtime');
var DispatcherSingleton = require("./../modules/dispatcher");
var StageClass = require("./../modules/stage");
var registryInstance = require('./../modules/registry');
var utilInstance = require("./../modules/util");
var LoaderSingleton = require("./../modules/loader");
var fsm = require("./../modules/stateMachine");



var Gamesito = function (config, lang) {

    var defaultConf = require("./../default.conf.js");
    var mergeConf = utilInstance.deepExtend( defaultConf, config);
    registryInstance.add("config", mergeConf);

    config = registryInstance.get("config");
    this.config = config;
    this.version = "0.2.1";
    this.sound = SoundSingleton;
    this.sound.init(config);
    this.loader = LoaderSingleton;
    this.loader.init(config);
    this.stage = null;
    this.containers = [];
};

var p = Gamesito.prototype;
p.Stage = new StageClass();
p.Container = new ContainerClass();
p.Sprite = SpriteClass;
p.Text = TextClass;
p.Spritesheet = SpriteSheetClass;
p.RealTime = RealtimeClass;
p.Ajax = reqwestSingleton;
p.Promise = Promise;
p.registry = registryInstance;
p.util = utilInstance;
p.dispatcher = DispatcherSingleton;
p.queue = new QueueSingleton();
p.fsm = fsm;
p.ontick = function (e) {
    this.stage.update(e);
};
p.init = require('./init');
window.Gamesito = Gamesito;
