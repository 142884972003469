var createjs = require('createjs');

var container = function () {
    // private properties and methods
    var _create = function(name, spritesheet) {
        if (spritesheet) {      // webgl
            return new createjs.SpriteContainer(spritesheet);
        }
        else {                  // canvas 2d
            var cont = new createjs.Container();
            cont.name = name;
            return cont;
        }
    };
    // public properties and methods
    return {
        create: _create
    };
};

module.exports = container;